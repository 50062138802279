import React from "react"
import { Link } from "gatsby"
import Logo from 'images/logo.svg'
import styles from './404.module.scss'

export default () => (
  <>
    <div className={styles.blockLogo}>
      <Link to="/">
        <img src={Logo} alt="Binary Stride Logo" className={styles.imgLogo} />
      </Link>
    </div>
    <div className={styles.textHeader}>Wooo... this page does not exist. It's code 404!</div>
    <div className={styles.textDesc}>We apologize if you've clicked somewhere on a link and it brought you here.</div>
  </>
)

